import { mapState } from 'vuex';
export default {
  name: "product_detail",
  data() {
    return {
      title: this.$api.api_title + 'uploadImage/',
      productDetail: {},
      development: [],
      detailBoxBtn: 1,
      showLpayer: false,
      playLink: '',
      color: '#7bd5c5'
    };
  },
  mounted() {},
  watch: {
    '$route'(to, from) {
      this.getData(this.$route.query.productName);
    }
  },
  created() {
    console.log(this.$route.query);
    this.getData(this.$route.query.productName);
  },
  computed: {
    ...mapState({
      device: state => state.device,
      language: state => state.language
    }),
    styleObject() {
      return {
        "--active-color": this.color
      };
    }
  },
  methods: {
    closePlayer() {
      this.showLpayer = false;
      this.playLink = '';
    },
    playVideo(event) {
      this.showLpayer = true;
      var el = event.target;
      this.playLink = el.getAttribute('data-link');
    },
    getData(productName) {
      this.$http.post(this.$api.product_list, {
        productName: productName,
        nhLanguage: this.$store.state.language
      }, {
        emulateJSON: true
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            if (this.$store.state.language == 1) {
              this.productDetail = res.data[0];
              this.color = res.data[0].productColor;
              console.log(res.data);
            } else if (this.$store.state.language == 2) {
              this.productDetail = res.data[0];
              this.color = res.data[0].productColor;
            } else if (this.$store.state.language == 3) {
              this.productDetail = res.data[0];
              this.color = res.data[0].productColor;
            }
          }
        }
      });
    }
  }
};